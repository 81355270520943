/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2022-01-21 15:15:08
 * @LastEditors: ------
 * @LastEditTime: 2022-02-28 13:41:40
 */
import router from "@/router";
import axios from "axios";
//import router from "@/router/index.js";
import store from "@/store";
import { Toast } from "vant";

const request = axios.create({
    // baseURL: "https://api-test.aijiehu.cn/gateway-ext/", // api base_url
    baseURL: "https://api.aijiehu.cn/gateway-ext/", // api base_url
    // baseURL: "http://192.168.110.252:6101/gateway-ext/", //后端----------------------- 暂未填写
    // baseURL: "http://192.168.5.109:6101/gateway-ext/", //
    // headers: { "X-A ccess-Token": `${localStorage.getItem("user")}` },
    timeout: 6000, // 请求超时时间2549
});
var toast1;
request.interceptors.request.use(
    (config) => {
        toast1 = Toast.loading({ 
            message: "加载中...",
            forbidClick: true,
        });
        if (store.state.token) {
            config.headers["X-Access-Token"] = store.state.token;
        }
        return config;
    },
    (err) => {
        // 请求未成功发出，如：没有网络...
        //Toast.fail("加载失败！");
        return Promise.reject(err);
    }
);

request.interceptors.response.use(
    (response) => {
        console.log(response)
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            toast1.clear();
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        if (
            error.response.status &&
            (error.response.status === 401 ||
                // error.response.status === 500 ||
                error.response.status === 403)
        ) {
            router.push({ path: "/login" });
            localStorage.removeItem('token')
            toast1.clear();
            //Toast.fail("加载失败！");
        }
    }
);

export default request;